.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.div-title {
  margin: auto;
  width: 100vw;
  height: 18vw;
}

.div-title-light {
  background-color: #112222;
}

.div-title h1 {
  position: absolute;
  padding: 3vw;
  font-size: 14vw;
  font-family: 'Roboto Condensed', sans-serif;
  color: #FFFFFF;
}

.nav-container {
  display: flex;
  flex-direction: row;
  height: 5.1vw;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3vw
}

.nav {
  opacity: .6;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-style: none none solid none; 
  border-width: 2px;
  border-color: white;
}

.nav:hover{
  opacity: 1;
}

.home {
  background-color: #FF2B14;
}

.blog {
  background-color: #137d7d;
}

.projects {
  background-color: #FAB40C;
}

.music {
  background-color: #FA4D7A;
}

.main-margin {
  position: relative;
  height: 10vw;
}

.light {
  background-color: white;
  border-color: black;
}

.dark {
  background-color: #282c34;
  border-color: white;
}

.div-main {
  display: grid;
  grid-template-columns: 50vw 50vw;
}

.light h2 {
  font-size: 4vw;
  padding: 2vw;
  font-family: 'EB Garamond', serif;
}

.dark h2 {
  font-size: 4vw;
  padding: 2vw;
  font-family: 'EB Garamond', serif;
  color: white;
}

.dark p {
  font-size: 2vw;
  padding: 2vw;
  font-family: 'EB Garamond', serif;
  color: white;
}

.light p {
  font-size: 2vw;
  padding: 2vw;
  font-family: 'EB Garamond', serif;
  color: black;
}

.mainContainer {
  position: relative;
  padding: 3vw;
  left: 10vw;
  min-height: 30vw;
  width: 30vw;
  border-style: solid none none none; 
  border-width: 2px;
}

.mainContainer img {
  height: 30vw;
}

.toggle-darkmode {
  position: relative;
  left: calc(99% - 60px);
  top: calc(92% - 34px);
  width: 60px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #122;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.back-to-top {
  position: fixed;
  right: 5%;
  bottom: 5%;
  margin: auto;
  background-color: #112222;
  height: 2.5vw;
  width: 2.5vw;
  border-radius: 15vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;    
}

.back-to-top:hover {
  font-size: 150%;
}

.mainPic {
  justify-content: center;
  height: inherit;
}
