@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond');

#div-title {
    margin: auto;
    background-color: #112222;
    width: 100%vw;
    height: 20vw;
}

#div-title h1 {
    padding: 3vw;
    font-size: 14vw;
    font-family: 'Roboto Condensed', sans-serif;
    color: #FFFFFF;
}

#nav-container {
    display: flex;
    flex-direction: row;
    height: 5vw;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 3vw
}

#nav-1 {
    opacity: .6;
    display: flex;
    background-color: #FF2B14;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

#nav-1:hover{
    opacity: 1;
}

#nav-2 {
    opacity: .6;
    display: flex;
    background-color: #137d7d;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

#nav-2:hover{
    opacity: 1;
}

#nav-3 {
    opacity: .6;
    display: flex;
    background-color: #FAB40C;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

#nav-3:hover{
    opacity: 1;
}

#nav-4 {
    opacity: .6;
    display: flex;
    background-color: #FA4D7A;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

#nav-4:hover {
    opacity: 1;
}

#main-margin {
    position: relative;
    height: 10vw;
    background-color: #FFFFFF;
}

#div-main {
    display: grid;
    grid-template-columns: 50vw 50vw;
    background-color: #FFFFFF;
}

#div-main h2 {
    font-size: 4vw;
    padding: 2vw;
    font-family: 'EB Garamond', serif;
}

#div-main p {
    font-size: 2vw;
    padding: 2vw;
    font-family: 'EB Garamond', serif;
}

#main1, #main1a, #main2, #main2a, #main3, #main3a {
    position: relative;
    padding: 3vw;
    left: 10vw;
    height: 30vw;
    width: 30vw;
    border-style: solid none none none;
    border-color: black;
    border-width: 2px;
}

#back-to-top {
    position: fixed;
    right: 5%;
    bottom: 5%;
    margin: auto;
    background-color: #112222;
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 15vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#back-to-top:hover {
    font-size: 150%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}